import React, { useRef, useEffect } from 'react';
import '../styles/PurVaht.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHand, faHouseCircleCheck, faArrowsToCircle, faVolumeDown, faWind, faDroplet, faClock, faBolt, faGroupArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation, useInView } from "framer-motion";

function PurVaht() {
    const purvahtRef = useRef(null);
    const eelisedRef = useRef(null);

    const purvahtInView = useInView(purvahtRef, { once: true });
    const eelisedInView = useInView(eelisedRef, { once: true });

    const purvahtControls = useAnimation();
    const eelisedControls = useAnimation();

    useEffect(() => {
        if (purvahtInView) {
            purvahtControls.start("visible");
        }

        if (eelisedInView) {
            eelisedControls.start("visible");
        }
    }, [purvahtInView, eelisedInView, purvahtControls, eelisedControls]);

    return ( 
        <div className="purvaht">
            <motion.div className="section"
                ref={purvahtRef}
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={purvahtControls}
                transition={{ duration: 0.3 }}
            >
                <div className="text">
                    <FontAwesomeIcon className='symbol' color="rgb(21, 78, 21)" icon={faWind} size='4x' />
                    <h1>PUR vaht</h1>
                    <p>
                        Polüuretaanvaht ehk PUR vaht on soojustusmaterjal, millega soojustatakse fassaade, vundamente, pööninguid, vaheseinu, sokleid ja siseseinu.
                        Materjal tekib kahe erineva keemilise elemendi omavahelisel segunemisel ja paisumisel.
                        Seda paigaldatakse kas pritsides vahtu otse aluspinnale või süstides seintesse läbi puuritud augukeste.
                        Materjal hakkab paisuma kohe pärast paigaldamist väga kiiresti, täites ära tühimikud ja õhuvahed.
                    </p>
                    <br /><br />
                    <h2>PUR vaht jaguneb kahte erinevasse gruppi</h2>
                    <ul>
                        <li>
                            <h3>Avatud pooridega PUR vaht</h3>
                            <div>Materjali tihedus on 7-12kg/m<sup>3</sup>.</div>
                            <div>Sulgeb õhuliikumise, kuid laseb veeaurul endast läbi liikuda, mistõttu on ka vajalik auru-/tuuletõkke paigaldus.</div>
                        </li>
                        <li>
                            <h3>Suletud pooridega PUR vaht</h3>
                            <div>Materjali tihedus on 33-40kg/m<sup>3</sup>.</div>
                            <div>Sulgeb õhuliikumise ja on ka veekindel, auru-/tuuletõkke paigaldamist ei ole vaja.</div>
                        </li>
                    </ul>
                </div>
            </motion.div>
            <div className='horizontal_line'></div>
            <motion.div className="eelised"
                ref={eelisedRef}
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={eelisedControls}
                transition={{ duration: 0.3 }}
            >
                <motion.div className='videobox'
                    ref={eelisedRef}
                    variants={{
                        hidden: { opacity: 0, x: -75 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={eelisedControls}
                    transition={{ duration: 0.3 }}
                >
                    <video className='video_circle' src="purvaht_video.mp4" autoPlay loop muted />
                </motion.div>
                <motion.div className='list'
                    ref={eelisedRef}
                    variants={{
                        hidden: { opacity: 0, x: 75 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={eelisedControls}
                    transition={{ duration: 0.3 }}
                >
                    <h1>PUR vahu paigaldamise eelised</h1>
                    <div className='list_row'>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faHand} size='2x' />
                            <p>Kiiresti paigaldatav</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faArrowsToCircle} size='2x' />
                            <p>Lihtsasti paigaldatav ka kitsastes kohtades</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faHouseCircleCheck} size='2x' />
                            <p>Konstruktsioonide vastupidavuse tugevdamine</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faDroplet} size='2x' />
                            <p>Materjal tõrjub hallituse ja seente teket</p>
                        </div>
                    </div>
                    <div className='list_row'>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faVolumeDown} size='2x' />
                            <p>Hea heliisolatsioon</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faGroupArrowsRotate} size='2x' />
                            <p>Tagab parema sisekliima kvaliteedi</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faClock} size='2x' />
                            <p>Suurepärane vastupidavus aja suhtes</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faBolt} size='2x' />
                            <p>Küttekulutuste vähenemine</p>
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </div>
     );
}

export default PurVaht;