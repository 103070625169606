import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';

function NavBar() {
	const [menu, navMenuClicked] = useState(false);

	return (
		<div className="header">
			<img src="/logo1_white.png" alt="Logo" title="Greenec OÜ" className="logo" />
			<div className="navlinks">
				<ul>
					<Link to="" className="navlink"><li className="special">Avaleht</li></Link>
					<Link to="puistevill" className="navlink"><li className="special">Puistevilla paigaldamine</li></Link>
					<Link to="pur-vaht" className="navlink"><li className="special">PUR vahu paigaldamine</li></Link>
					<Link to="tehtud-tood" className="navlink"><li className="special">Tehtud tööd</li></Link>
					<Link to="kontakt" className="navlink"><li className="special">Kontakt</li></Link>
				</ul>
			</div>
            <div className='mobile_dropdown'>
				{menu === true && <FontAwesomeIcon onClick={() => navMenuClicked(false)} className='nav_bars' size='3x' icon={faXmark} />}
				{menu === false && <FontAwesomeIcon onClick={() => navMenuClicked(true)} className='nav_bars' size='3x' icon={faBars} />}
            </div>
			{menu === true && 
				<div className='mobile_navlinks'>
					<ul>
						<li><Link onClick={() => navMenuClicked(false)} to="" className='navlink'>Avaleht</Link></li>
						<li><Link onClick={() => navMenuClicked(false)} to="puistevill" className='navlink'>Puistevilla paigaldamine</Link></li>
						<li><Link onClick={() => navMenuClicked(false)} to="pur-vaht" className='navlink'>PUR vahu paigaldamine</Link></li>
						<li><Link onClick={() => navMenuClicked(false)} to="tehtud-tood" className='navlink'>Tehtud tööd</Link></li>
						<li><Link onClick={() => navMenuClicked(false)} to="kontakt" className='navlink'>Kontakt</Link></li>
					</ul>
				</div>
			}
		</div>
	);
}

export default NavBar;