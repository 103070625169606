import React from 'react';
import './styles/Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="copyright">
            Greenec OÜ © 2023
            </div>
        </div>
    );
};

export default Footer;