import React from 'react';
import '../styles/PopUp.css';

function Popup(props) {
    return (props.trigger) ? (
        <div className='popup'>
            <div className='popup_outer' onClick={() => props.setTrigger(false)}></div>
            <div className='popup_inner'>
                {props.children}
            </div>
        </div>
    ) : "";
}

export default Popup;