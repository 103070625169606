import React, { useRef, useEffect } from 'react';
import ImageSlider from '../plugins/ImageSlider';
import Popup from '../plugins/PopUp';
import { useState } from 'react';
import smallPuistevillPildidFailist from '../pildid_puistevill_small.json';
import smallPurvahtPildidFailist from '../pildid_purvaht_small.json';
import puistevillPildidFailist from '../pildid_puistevill.json';
import purvahtPildidFailist from '../pildid_purvaht.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft, faCircleRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import '../styles/TehtudTood.css';
import { motion, useAnimation, useInView } from "framer-motion";

function TehtudTood() {
    const [button, setButton] = useState(false);
    const [uus, uusPilt] = useState();
    const [currentAlbum, setCurrentAlbum] = useState([]);
    const [pictureLoading, setPictureLoading] = useState({});

    const sliderRef = useRef(null);
    const puistevillRef = useRef(null);
    const purvahtRef = useRef(null);

    const sliderInView = useInView(sliderRef, { once: true });
    const puistevillInView = useInView(puistevillRef, { once: true });
    const purvahtInView = useInView(purvahtRef, { once: true });

    const sliderControls = useAnimation();
    const puistevillControls = useAnimation();
    const purvahtControls = useAnimation();

    useEffect(() => {
        if (sliderInView) {
            sliderControls.start("visible");
        }

        if (puistevillInView) {
            puistevillControls.start("visible");
        }

        if (purvahtInView) {
            purvahtControls.start("visible");
        }
    }, [sliderInView, puistevillInView, purvahtInView, sliderControls, puistevillControls, purvahtControls]);

    const kuvaPilt = (album, index) => {
        if(index < 0) {
            index = album.length - 1;
        }
        else if(index >= album.length) {
            index = 0;
        }

        setCurrentAlbum(album);
        setButton(true);
        uusPilt(index);
    }

    const handlePictureLoad = (src) => {
        setPictureLoading((prevState) => ({ ...prevState, [src]: true }));
    };
      
    const isPictureLoaded = (src) => {
        return pictureLoading[src];
    };

    return (
        <div className="tehtud_tood">
            <motion.div className="ImageSlider"
                ref={sliderRef}
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={sliderControls}
                transition={{ duration: 0.3 }}
            >
                <ImageSlider />
            </motion.div>
            <div className='horizontal_line'></div>
            <div className='pildid'>
                <motion.div className='pildid_kategooria'
                    ref={puistevillRef}
                    variants={{
                        hidden: { opacity: 0, x: -75 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={puistevillControls}
                    transition={{ duration: 0.3 }}
                >
                    <h1>Puistevill</h1>
                    {smallPuistevillPildidFailist.map((element, index) => 
                        <div key={index}>
                            <img onClick={() => kuvaPilt(puistevillPildidFailist, index)} src={element} alt="Tehtud töö" />
                        </div>
                    )}
                </motion.div>
                <motion.div className='pildid_kategooria'
                    ref={purvahtRef}
                    variants={{
                        hidden: { opacity: 0, x: 75 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={purvahtControls}
                    transition={{ duration: 0.3 }}
                >
                    <h1>PUR vaht</h1>
                    {smallPurvahtPildidFailist.map((element, index) => 
                        <div key={index}>
                            <img onClick={() => kuvaPilt(purvahtPildidFailist, index)} src={element} alt="Tehtud töö" />
                        </div>
                    )}
                </motion.div>
            </div>
            <Popup trigger={button} setTrigger={setButton}>
                <img src={currentAlbum[uus]} alt="Tehtud töö" onLoad={() => handlePictureLoad(currentAlbum[uus])} />
                {isPictureLoaded(currentAlbum[uus]) && (
                    <>
                        <button className='close_btn' onClick={() => setButton(false)}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </button>
                        <button className='prev_btn' onClick={() => kuvaPilt(currentAlbum, uus - 1)}>
                            <FontAwesomeIcon icon={faCircleLeft} />
                        </button>
                        <button className='next_btn' onClick={() => kuvaPilt(currentAlbum, uus + 1)}>
                            <FontAwesomeIcon icon={faCircleRight} />
                        </button>
                    </>
                )}
            </Popup>
        </div>
     );
}

export default TehtudTood;