import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ImageSlider from '../plugins/ImageSlider';
import '../styles/Avaleht.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation, useInView } from "framer-motion";

function Avaleht() {
    const meistRef = useRef(null);
    const teenusedRef = useRef(null);
    const teenusRef = useRef(null);
    const tehtudtoodRef = useRef(null);

    const meistInView = useInView(meistRef, { once: true });
    const teenusedInView = useInView(teenusedRef, { once: true });
    const teenusInView = useInView(teenusRef, { amount: 0.36, once: true });
    const tehtudtoodInView = useInView(tehtudtoodRef, { amount: 0.36, once: true });

    const meistControls = useAnimation();
    const teenusedControls = useAnimation();
    const teenusControls = useAnimation();
    const tehtudtoodControls = useAnimation();

    useEffect(() => {
        if (meistInView) {
            meistControls.start("visible");
        }

        if (teenusedInView) {
            teenusedControls.start("visible");
        }

        if (teenusInView) {
            teenusControls.start("visible");
        }

        if (tehtudtoodInView) {
            tehtudtoodControls.start("visible");
        }
    }, [meistInView, teenusedInView, teenusInView, tehtudtoodInView, meistControls, teenusedControls, teenusControls, tehtudtoodControls]);

    return ( 
        <div className="avaleht">
            <motion.div 
                className='section'
                ref={meistRef}
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={meistControls}
                transition={{ duration: 0.3 }}
            >
                <motion.div className='text'
                    ref={meistRef}
                    variants={{
                        hidden: { opacity: 0, x: -75 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={meistControls}
                    transition={{ duration: 0.3, delay: 0.2 }}
                >
                    <FontAwesomeIcon className='symbol' color="rgb(21, 78, 21)" icon={faCircleInfo} size='4x' />
                    <h1>Meist</h1>
                    <p>
                        Greenec OÜ tegeleb hoonete soojustamisega, alustades vundamendist kuni katuseni välja.
                        Selleks me kasutame kahte erinevat materjali, milleks on puistevill ja PUR vaht.
                        Meie seadmed, kasutatavad materjalid ning tööd teostavad inimesed tagavad kiire ja kvaliteetse tulemuse.
                        Ettevõte on asustatud 2021 aastal ning pakub teenust üle kogu Eesti.
                    </p>
                </motion.div>
                <motion.div className='image'
                    ref={meistRef}
                    variants={{
                        hidden: { opacity: 0, x: 75 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={meistControls}
                    transition={{ duration: 0.3, delay: 0.2 }}
                >
                    <img src="/greenec_ou.jpg" alt="Greenec OÜ" />
                </motion.div>
            </motion.div>
            <motion.div 
                className="teenused_avaleht"
                ref={teenusedRef}
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={teenusedControls}
                transition={{ duration: 0.3 }}
            >
                <h1>Teenused</h1>
                <div className="valikud">
                    <motion.div className='valik'
                        ref={teenusRef}
                        variants={{
                            hidden: { opacity: 0, x: -75 },
                            visible: { opacity: 1, x: 0 },
                        }}
                        initial="hidden"
                        animate={teenusControls}
                        transition={{ duration: 0.3, delay: 0.2 }}
                    >
                        <h2>Puistevilla paigaldamine</h2>
                        <Link to="puistevill">
                            <video className='video_circle' src="puistevill_video.mp4" autoPlay loop muted />
                        </Link>
                        <p>
                            Soodne ja efektiivne viis hoone soojustamiseks.
                            Materjal on hingav ja väga hea soojapidavusega.
                        </p>
                    </motion.div>
                    <motion.div className='valik'
                        ref={teenusRef}
                        variants={{
                            hidden: { opacity: 0, x: 75 },
                            visible: { opacity: 1, x: 0 },
                        }}
                        initial="hidden"
                        animate={teenusControls}
                        transition={{ duration: 0.3, delay: 0.2 }}
                    >
                        <h2>PUR vahu paigaldamine</h2>
                        <Link to="pur-vaht">
                            <video className='video_circle' src="purvaht_video.mp4" autoPlay loop muted />
                        </Link>
                        <p>
                            Suurepärane soojustusmaterjal, mida saab kasutada nii vundamendi kui ka seinte ja katuste soojustamiseks.
                        </p>
                    </motion.div>
                </div>
            </motion.div>
            <motion.div className="ImageSlider"
                ref={tehtudtoodRef}
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={tehtudtoodControls}
                transition={{ duration: 0.3 }}
            >
                <Link to="tehtud-tood" style={{ textDecoration: 'none' }}>
                    <h1>Tehtud tööd</h1>
                </Link>
                <ImageSlider />
            </motion.div>
        </div>
     );
}

export default Avaleht;