import React from 'react';
import { Slide } from 'react-slideshow-image';
import './ImageSlider.css';

function ImageSlider() {
    const images = [
        "../puistevill_pildid/IMG_20220520_133906.jpg",
        "../puistevill_pildid/IMG_20221102_184627.jpg",
        "../puistevill_pildid/IMG_20221105_104432.jpg",
        "../puistevill_pildid/IMG_20221105_125943.jpg",
        "../purvaht_pildid/IMG_20210702_144140.jpg",
        "../purvaht_pildid/IMG_20210706_100428.jpg",
        "../purvaht_pildid/IMG_20211015_123735.jpg",
        "../purvaht_pildid/IMG_20211005_104031.jpg"
    ];

    return ( 
        <Slide indicators={true} transitionDuration={500}>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[3]})` }}>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[4]})` }}>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[5]})` }}>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[6]})` }}>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[7]})` }}>
                </div>
            </div>
        </Slide> 
    );
}

export default ImageSlider;