import React, { useEffect, useRef } from 'react';
import '../styles/Puistevill.css';
import villad from '../villad.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHand, faLeaf, faHouseCircleCheck, faArrowsToCircle, faFire, faVolumeDown, faTree, faRecycle, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation, useInView } from "framer-motion";

function Puistevill() {
    const puistevillRef = useRef(null);
    const eelisedRef = useRef(null);
    const villadRef = useRef(null);

    const puistevillInView = useInView(puistevillRef, { once: true });
    const eelisedInView = useInView(eelisedRef, { once: true });
    const villadInView = useInView(villadRef, { amount: 0.2, once: true });

    const puistevillControls = useAnimation();
    const eelisedControls = useAnimation();
    const villadControls = useAnimation();

    useEffect(() => {
        if (puistevillInView) {
            puistevillControls.start("visible");
        }

        if (eelisedInView) {
            eelisedControls.start("visible");
        }

        if (villadInView) {
            villadControls.start("visible");
        }
    }, [puistevillInView, eelisedInView, villadInView, puistevillControls, eelisedControls, villadControls]);

    return ( 
        <div className="puistevill">
            <motion.div className="section"
                ref={puistevillRef}
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={puistevillControls}
                transition={{ duration: 0.3 }}
            >
                <div className="text">
                    <FontAwesomeIcon className='symbol' color="rgb(21, 78, 21)" icon={faLeaf} size='4x' />
                    <h1>Puistevill</h1>
                    <p>
                        Puistevill on samasugune vill nagu rull- või plaatvill, kuid purustatud kujul ning see on kokku pressitud kilepakendisse.
                        Pakend tühjendatakse masinasse, mis tükeldab villa ja suunab läbi voolikute kohtadesse, mis vajavad soojustamist.
                        See on soodne ja efektiivne viis, kuidas soojustada oma kodu.
                        Paigaldades jääb villakiudude vahele õhuline ruum, mis tagab suurepärase hingamise ning soojuspidavuse.
                         Lisaks tagab see ka hea heli isolatsiooni.
                    </p>
                </div>
            </motion.div>
            <div className='horizontal_line'></div>
            <motion.div className="eelised"
                ref={eelisedRef}
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={eelisedControls}
                transition={{ duration: 0.3 }}
            >
                <motion.div className='videobox'
                    ref={eelisedRef}
                    variants={{
                        hidden: { opacity: 0, x: -75 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={eelisedControls}
                    transition={{ duration: 0.3, delay: 0.2 }}
                >
                    <video className='video_circle' src="puistevill_video.mp4" autoPlay loop muted />
                </motion.div>
                <motion.div className='list'
                    ref={eelisedRef}
                    variants={{
                        hidden: { opacity: 0, x: 75 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={eelisedControls}
                    transition={{ duration: 0.3, delay: 0.2 }}
                >
                    <h1>Puistevilla paigaldamise eelised</h1>
                    <div className='list_row'>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faHand} size='2x' />
                            <p>Kiirelt ja lihtsalt paigaldatav</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faArrowsToCircle} size='2x' />
                            <p>Võimalik soojustada kitsaid kohti</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faHouseCircleCheck} size='2x' />
                            <p>Suurepärane soojuspidavus</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faFire} size='2x' />
                            <p>Mittepõlev materjal</p>
                        </div>
                    </div>
                    <div className='list_row'>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faVolumeDown} size='2x' />
                            <p>Hea heliisolatsioon</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faTree} size='2x' />
                            <p>Keskkonnasõbralik</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faRecycle} size='2x' />
                            <p>Ei teki materajli ülejääke</p>
                        </div>
                        <div className='list_element'>
                            <FontAwesomeIcon color="rgb(21, 78, 21)" icon={faEuroSign} size='2x' />
                            <p>Soodne hind</p>
                        </div>
                    </div>
                </motion.div>
            </motion.div>
            <div className='horizontal_line'></div>
            <motion.div className="vill"
                ref={villadRef}
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={villadControls}
                transition={{ duration: 0.3 }}
            >
                <h1>Meie kasutatavad villad</h1>
                <div className="villad">
                    {villad.map((element, index) => 
                        <div key={index} className="kast">
                            <img src={element.pilt} alt={element.nimi} />
                            <h2>{element.nimi}</h2>
                            <p>{element.lyhikirjeldus}</p>
                            <button>
                                <a href={element.url} target="_blank" rel="noopener noreferrer">Loe lähemalt tootja lehelt</a>
                            </button>
                        </div>
                    )}
                </div>
            </motion.div>
        </div>
     );
}

export default Puistevill;