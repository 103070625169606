import { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import '../styles/Kontakt.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion, useAnimation, useInView } from "framer-motion";

function Kontakt() {
    const eesnimiRef = useRef();
    const perenimiRef = useRef();
    const telefonRef = useRef();
    const epostRef = useRef();
    const teenusRef = useRef();
    const pindalaRef = useRef();
    const kihipaksusRef = useRef();
    const aadressRef = useRef();
    const kuupaevRef = useRef();
    const muuRef = useRef();

    const contactinfoRef = useRef(null);
    const contactfieldsRef = useRef(null);

    const contactinfoInView = useInView(contactinfoRef, { once: true });
    const contactfieldsInView = useInView(contactfieldsRef, { once: true });

    const contactinfoControls = useAnimation();
    const contactfieldsControls = useAnimation();

    useEffect(() => {
        if (contactinfoInView) {
            contactinfoControls.start("visible");
        }

        if (contactfieldsInView) {
            contactfieldsControls.start("visible");
        }
    }, [contactinfoInView, contactfieldsInView, contactinfoControls, contactfieldsControls]);

    const sendEmail = () => {
        if(eesnimiRef.current.value === "") {
            toast.error("Eesnime välja täitmine on kohustuslik!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
        else if(telefonRef.current.value === "") {
            toast.error("Telefoni numbri välja täitmine on kohustuslik!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
        else if(epostRef.current.value === "") {
            toast.error("E-posti aadressi välja täitmine on kohustuslik!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
        else if(teenusRef.current.value === "") {
            toast.error("Tellitava teenuse välja täitmine on kohustuslik!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            toast.success("Pakkumise küsimine on edukalt teostatud!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });

            const data = {
                "eesnimi": eesnimiRef.current.value,
                "perenimi": perenimiRef.current.value,
                "telefon": telefonRef.current.value,
                "epost": epostRef.current.value,
                "teenus": teenusRef.current.value,
                "pindala": pindalaRef.current.value,
                "kihipaksus": kihipaksusRef.current.value,
                "aadress": aadressRef.current.value,
                "kuupaev": kuupaevRef.current.value,
                "muu": muuRef.current.value,
            }
        
            emailjs.send('service_e3xtvsi', 'template_uapxi4h', data, 'XAdlUtKwuKBMdc8av')
            .then((result) => {
                console.log(result.text);
                eesnimiRef.current.value = "";
                perenimiRef.current.value = "";
                telefonRef.current.value = "";
                epostRef.current.value = "";
                teenusRef.current.value = "";
                pindalaRef.current.value = "";
                kihipaksusRef.current.value = "";
                aadressRef.current.value = "";
                kuupaevRef.current.value = "";
                muuRef.current.value = "";
            }, (error) => {
                console.log(error.text);
            });
        }
    }

    return ( 
        <div className="kontakt">
            <motion.div 
                className="left"
                ref={contactinfoRef}
                variants={{
                    hidden: { opacity: 0, x: -75 },
                    visible: { opacity: 1, x: 0 },
                }}
                initial="hidden"
                animate={contactinfoControls}
                transition={{ duration: 0.3 }}
            >
                <h1>Kontaktandmed</h1>
                <h2>
                    Greenec OÜ
                    <br /><br />
                    <a href="tel:+372-56970076"><FontAwesomeIcon color="rgb(37, 82, 37)" fixedWidth icon={faPhone} /></a> Telefon: +372 56 97 00 76
                    <br />
                    <a href="mailto: ougreenec@gmail.com"><FontAwesomeIcon color="rgb(37, 82, 37)" fixedWidth icon={faEnvelope} /></a> E-post: ougreenec@gmail.com
                    <br />
                    <a href="https://goo.gl/maps/s5Pruraj8e63JQg8A" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon color="rgb(37, 82, 37)" fixedWidth icon={faLocationDot} /></a> Aadress: Nõo, 61601, Tartu maakond
                    <br />
                    <a href="https://www.facebook.com/greenec.ou/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon color="rgb(37, 82, 37)" fixedWidth icon={faFacebook} /></a> Facebooki leht
                    <br /><br />
                    Reg nr: 16350311
                    <br />
                    Arvelduskonto LHV pangas: EE147700771007469203
                </h2>
                <div className='contact_logo'>
                    <img src="/logo2_green.png" alt="Logo" title="Greenec OÜ" />
                </div>
            </motion.div>
            <div className="center_vertical_line"></div>
            <div className="horizontal_line"></div>
            <motion.div 
                className="form"
                ref={contactfieldsRef}
                variants={{
                    hidden: { opacity: 0, x: 75 },
                    visible: { opacity: 1, x: 0 },
                }}
                initial="hidden"
                animate={contactfieldsControls}
                transition={{ duration: 0.3 }}
            >
                <div>
                    <h1>Küsi pakkumist</h1>
                </div>
                <div className="inputBox">
                    <input ref={eesnimiRef} type="text" placeholder=" " className="form_input" id="eesnimi" required />
                    <label className="form_label" htmlFor="eesnimi">Eesnimi</label>
                </div>
                <div className="inputBox">
                    <input ref={perenimiRef} type="text" placeholder=" " className="form_input" id="perenimi" />
                    <label className="form_label" htmlFor="perenimi">Perekonnanimi</label>
                </div>
                <div className="inputBox">
                    <input ref={telefonRef} type="text" placeholder=" " className="form_input" id="telefon" required />
                    <label className="form_label" htmlFor="telefon">Telefoni number</label>
                </div>
                <div className="inputBox">
                    <input ref={epostRef} type="text" placeholder=" " className="form_input" id="epost" required />
                    <label className="form_label" htmlFor="epost">E-posti aadress</label>
                </div>
                <div className="inputBox">
                    <select ref={teenusRef} id="teenus" className="form_select" required>
                        <option selected disabled></option>
                        <option value="Puistevill">Puistevilla paigaldamine</option>
                        <option value="Pur-vaht">PUR vahu paigaldamine</option>
                    </select>
                    <label className="form_label" htmlFor="teenus">Tellitav teenus</label>
                </div>
                <div className="inputBox">
                    <input ref={pindalaRef} type="text" placeholder=" " className="form_input" id="pindala" />
                    <label className="form_label" htmlFor="pindala">Pindala (m<sup>2</sup>)</label>
                </div>
                <div className="inputBox">
                    <input ref={kihipaksusRef} type="text" placeholder=" " className="form_input" id="paksus" />
                    <label className="form_label" htmlFor="paksus">Kihi paksus (cm)</label>
                </div>
                <div className="inputBox">
                    <input ref={aadressRef} type="text" placeholder=" " className="form_input" id="aadress" />
                    <label className="form_label" htmlFor="aadress">Aadress</label>
                </div>
                <div className="inputBox">
                    <input ref={kuupaevRef} type="text" placeholder=" " className="form_input" id="kuupaev" />
                    <label className="form_label" htmlFor="kuupaev">Kuupäev</label>
                </div>
                <div className="inputBox">
                    <textarea ref={muuRef} id="muu" placeholder=" " className="form_input" />
                    <label className="form_label" htmlFor="muu">Muu informatsioon</label>
                </div>
                <button onClick={sendEmail}>Saada</button>
            </motion.div>
            <ToastContainer 
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover={false}
                theme="light"
            />
        </div>
     );
}

export default Kontakt;